import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useLocation, Link } from "react-router-dom";

import "./Footer.css";

import logoSvg from "./logo.png";

import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";

class Footer extends Component {
  render() {
    return (
      <div>
        <div className="footer">
          <div className="container">
            <Row>
              <Col sm>
                <div className="footer-col">
                  <h3>Company</h3>
                  <p>
                    <Link className="footer-link" to="/about">
                      About Us
                    </Link>
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="footer-col">
                  <h3>Pages</h3>
                  <p>
                    <Link className="footer-link" to="/service">
                      Services
                    </Link>
                  </p>
                  <p>
                    <Link className="footer-link" to="/career">
                      Careers
                    </Link>
                  </p>
                  <p>
                    <Link className="footer-link" to="/contact">
                      Contact
                    </Link>
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="footer-col">
                  <h3>Contact</h3>
                  <a href="mailto:messiahlearninginstitute@yahoo.com">
                    <p>messiahlearninginstitute@yahoo.com</p>
                  </a>
                  <a href="tel:+1 (240) 413-9930">
                    <p>+1 (240) 413-9930</p>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <div className="footer-lg container">
            <Row>
              <Col sm className="footer-logo">
                <img alt="" src={logoSvg} className="footerlogo" />
                &nbsp;&nbsp;Messiah Learning Institute
              </Col>
            </Row>
          </div>

          <div className="footer-social container">
            <FaFacebook className="icon fb" />
            <FaInstagram className="icon in" />
            <FaTwitter className="icon tw" />
            <FaYoutube className="icon yo" />
            <FaLinkedin className="icon li" />
          </div>

          <div className="footer-bootom text-center" style={{ paddingTop: 50 }}>
            <p>
              Messiah Learning Institute, Reg No. T00476322, Registered Office:
              11004 Hollaway Drive, Upper Marlboro, 20772.
            </p>
            <p>Messiah Learning Institute © 2024. All Rights Reserved.</p>
          </div>
        </div>
        <div
          className="text-center"
          style={{
            backgroundColor: "rgb(32, 41, 54)",
            marginTop: 39,
            color: "#68778d",
          }}
        >
          <a
            href="#"
            target="_blank"
            style={{
              color: "#68778d",
              fontSize: 12,
              // fontWeight: "bold",
            }}
          >
            ##
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
