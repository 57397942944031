import React from "react";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Index from "./Index/Index";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Service from "./Service/Service";
import Career from "./Career/Career";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/service" component={Service} />
          <Route exact path="/career" component={Career} />
          {/* <Route path="/save" component={Save} /> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
