import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WOW from "wowjs";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";

import "./Service.css";

import cardiacImg from "./images/cardiac.jpg";
import tmeImg from "./images/tme.jpg";
import childImg from "./images/child.jpg";
import nurseImg from "./images/nurse.jpg";
import directImg from "./images/direct.jpg";
import crisisImg from "./images/crisis.jpg";

export class Service extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div>
        <NavBar />
        <div className="service-hero">
          <div className="container">
            <Row>
              <Col>
                <div className="container">
                  <div className="service-header-text wow fadeInDown">
                    Services
                  </div>
                </div>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </div>
        <div className="service container">
          <Row>
            <Col sm>
              <h3>First Aid - CPR Training & Certification</h3>
              <p>
                Cardiopulmonary resuscitation (CPR) is an emergency procedure
                that combines chest compressions often with artificial
                ventilation in an effort to manually preserve intact brain
                function until further measures are taken to restore spontaneous
                blood circulation and breathing in a person who is in cardiac
                arrest.
              </p>
            </Col>
            <Col sm>
              <img src={cardiacImg} alt="cardiac" />
            </Col>
          </Row>
        </div>
        <div className="service container">
          <Row>
            <Col sm>
              <h3>CMT & TME Certification</h3>
              <p>
                After some time of medical staff training, you will know all
                about your specialty or your primary service.
              </p>
            </Col>
            <Col sm>
              <img src={tmeImg} alt="cmt/tme pic" />
            </Col>
          </Row>
        </div>
        <div className="service container">
          <Row>
            <Col sm>
              <h3>Child Care Training & Certification</h3>
              <p>
                Child care providers have one of the most important jobs - the
                care and education of children during the most crucial early
                learning years.
              </p>
            </Col>
            <Col sm>
              <img src={childImg} alt="child care pic" />
            </Col>
          </Row>
        </div>
        <div className="service container">
          <Row>
            <Col sm>
              <h3>CNA/GNA Training & Certification</h3>
              <p>
                A certified nursing assistant or certified nurse aide is someone
                who has successfully completed an approved nursing assistant
                training program.
              </p>
            </Col>
            <Col sm>
              <img src={nurseImg} alt="cna/gna pic" />
            </Col>
          </Row>
        </div>
        <div className="service container">
          <Row>
            <Col sm>
              <h3>Job Placement & Agency Services</h3>
              <p>
                We offer Job placement services and executive job placements and
                direct individuals in their career path.
              </p>
            </Col>
            <Col sm>
              <img src={directImg} alt="job placement pic" />
            </Col>
          </Row>
        </div>
        <div className="service container">
          <Row>
            <Col sm>
              <h3>CPI Training & Certification</h3>
              <p>
                CPI training can best be described as nonviolent crisis
                intervention training designed to teach best practices for
                managing difficult situations and disruptive behaviors. You
                learn how to identify at-risk individuals and use nonverbal and
                verbal techniques to defuse hostile behavior.
              </p>
            </Col>
            <Col sm>
              <img src={crisisImg} alt="cardiac" />
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Service;
