import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WOW from "wowjs";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";

import "./Career.css";

import careerImg from "./career.jpg";

export class Career extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div>
        <NavBar />
        <div className="career-hero">
          <div className="container">
            <Row>
              <Col>
                <div className="container">
                  <div className="career-header-text wow fadeInDown">
                    Careers
                  </div>
                </div>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </div>
        <div className="car container">
          <Row>
            <Col sm>
              <h3>We're a Team.</h3>
              <p>
                Call <a href="tel:+1 (240) 413-9930">+1 (240) 413-9930</a> or
                mail{" "}
                <a href="mailto:messiahlearninginstitute@yahoo.com">
                  messiahlearninginstitute@yahoo.com
                </a>
                &nbsp; for applications.
              </p>
            </Col>
            <Col sm>
              <img src={careerImg} alt="career pic" />
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Career;
