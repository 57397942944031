import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WOW from "wowjs";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";

import "./About.css";

export class About extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div>
        <NavBar />
        <div className="about-hero">
          <div className="container">
            <Row>
              <Col>
                <div className="container">
                  <div className="about-header-text wow fadeInDown">
                    About Us
                  </div>
                </div>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </div>
        <div className="container who">
          <p className="text-center whobg wow fadeInUp">Who we Are</p>
          <div className="container aboutheadercontainer wow fadeInUp">
            Our philosophy at Messiah Learning Institute is that students have
            the ability and potential to learn and should be given every
            available opportunity to succeed in their chosen career.
            <br />
            <br />
            Our training programs are of the highest quality that meets and
            exceeds all government standards, rules and regulations. We do not
            only teach our students how to provide care to others as a
            profession, we show it.
            <br />
            <br />
            Our programs are comprehensive, informative, and engaging. Our
            instructors possess a wealth of experience in healthcare generally
            and nursing in particular.
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
