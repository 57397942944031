import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WOW from "wowjs";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import "./ContactHero.css";

export class ContactHero extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div className="contact">
        <div className="contact-hero">
          <div className="container">
            <Row>
              <Col>
                <div className="container">
                  <div className="contact-header-text wow fadeInDown">
                    Contact Us
                  </div>
                </div>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </div>
        <div style={{ paddingTop: 100 }}>
          <div className="container wow fadeInUp">
            <Row>
              {/* <Col sm>
                <h4>Contact Us</h4>
                <Form>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Form.Group>
                  </Form.Row>

                  <Form.Group controlId="formGridAddress1">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="tel" placeholder="" />
                  </Form.Group>

                  <Form.Group controlId="formGridAddress2">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="" />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Contact
                  </Button>
                </Form>
              </Col> */}
              <Col sm className="contact-info">
                <div className="container wow fadeInUp">
                  <h4>Contact Information</h4>
                  <h5>Company Address</h5>
                  <p>11004 Hollaway Drive, Upper Marlboro, 20772.</p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.110728501232!2d-76.82331008500863!3d38.76116426256861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b797de89cdd33d%3A0x5da27b97c1cac4cb!2s11004%20Hollaway%20Dr%2C%20Upper%20Marlboro%2C%20MD%2020772%2C%20USA!5e0!3m2!1sen!2sng!4v1613050343055!5m2!1sen!2sng"
                    className="map"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                  <h5>Call Us</h5>
                  <a href="tel:+1 (240) 413-9930">
                    <p>+1 (240) 413-9930</p>
                  </a>
                  <h5>Email Us</h5>
                  <a href="mailto:messiahlearninginstitute@yahoo.com">
                    <p>messiahlearninginstitute@yahoo.com</p>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactHero;
