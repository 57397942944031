import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";
import ContactHero from "./ContactHero/ContactHero";

export default function Contact() {
  return (
    <div>
      <NavBar />
      <ContactHero />
      <Footer />
    </div>
  );
}
