import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WOW from "wowjs";

import { useLocation, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";

import { GiWorld } from "react-icons/gi";
import { FaHandsHelping } from "react-icons/fa";
import { GiStarsStack } from "react-icons/gi";
import { GiDiploma } from "react-icons/gi";
import { GoVerified } from "react-icons/go";
import { RiVirusLine } from "react-icons/ri";
import "./Index.css";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgClass: "hero-index1",
      currentNo: 1,
    };
  }

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
    this.interval = setInterval(() => {
      if (this.state.currentNo === 1) {
        this.setState({ bgClass: "hero-index2", currentNo: 2 });
      } else if (this.state.currentNo === 2) {
        this.setState({ bgClass: "hero-index3", currentNo: 3 });
      } else if (this.state.currentNo === 3) {
        this.setState({ bgClass: "hero-index4", currentNo: 4 });
      } else {
        this.setState({ bgClass: "hero-index1", currentNo: 1 });
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    var className = "hero-global " + this.state.bgClass;
    return (
      <div>
        <div className={className}>
          <NavBar />
          <div className="container">
            <Row>
              <Col sm>
                <div className="header-text">
                  <h3 className="wow fadeInUp">
                    Start Your Career
                    <br /> In Healthcare Today.
                  </h3>
                  <p className="wow fadeInUp">
                    {/* With impressive interest rates, tools & guides,
                    UpholdBanking is the smartest way to plan, send money, save
                    & invest.
                    <br />
                    <br /> */}
                    First Aid - CPR Training & Certification, CMT & TME
                    Certification, Child Care Training & Certification, CNA/GNA
                    Training & Certification, Job Placement & Agency Services,
                    CPI Training & Certification.
                  </p>
                </div>
              </Col>
              <Col sm></Col>
            </Row>
          </div>
        </div>
        <div className="world-class" style={{ paddingTop: 100 }}>
          <div className="container text-center wow fadeInUp">
            <GiWorld className="icon" />
            <h3> World Class, Learning Institute</h3>
            <p>Learn from the Best of the Best.</p>
          </div>
          <div className="map-bg"></div>
        </div>
        <div className="world-class" style={{ paddingTop: 100 }}>
          <div className="container text-center wow fadeInUp">
            <h3>What makes us Different</h3>
            <p>Lifetime placement assistance to all of our graduates.</p>
          </div>
          <div className="card-container" style={{ paddingTop: 50 }}>
            <Row>
              <Col sm>
                <div className="card-item wow fadeInUp">
                  <FaHandsHelping className="card-icon" />
                  <h4>
                    Lifetime Job <br />
                    Assistance
                  </h4>
                  <p>
                    Today's job market is not easy to navigate, which is why we
                    offer lifetime placement assistance to all of our graduates.
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="card-item wow fadeInUp">
                  <GiStarsStack className="card-icon" />
                  <h4>
                    Business
                    <br />
                    <br />
                  </h4>
                  <p>
                    We are in the Business of teaching and placement of
                    graduates.
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="card-item wow fadeInUp">
                  <GiDiploma className="card-icon" />
                  <h4>No High School Diploma Required</h4>
                  <p>No high school diploma is required.</p>
                </div>
              </Col>
              <Col sm>
                <div className="card-item wow fadeInUp">
                  <GoVerified className="card-icon" />
                  <h4>
                    Licensed
                    <br />
                    <br />
                  </h4>
                  <p>Licensed to operate.</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="world-class" style={{ paddingTop: 50 }}>
          <div className="covid-bg"></div>
          <div
            className="container text-center wow fadeInUp"
            style={{ paddingTop: 50 }}
          >
            <RiVirusLine className="icon" />
            <h3>COVID-19</h3>
            <p>Covid-19 Instructions according to the CDC guidelines.</p>
            <Button
              as={Link}
              to={{
                pathname:
                  "https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/quarantine.html",
              }}
              target="_blank"
              variant="primary"
              style={{ fontWeight: "" }}
            >
              READ MORE
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Index;
