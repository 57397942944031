import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
// import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { useLocation, Link } from "react-router-dom";

import logoSvg from "./logo.png";

import "./NavBar.css";

import React, { Component } from "react";

export class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navBackground: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener("scroll", () => {
      const backgroundcolor = window.scrollY < 100 ? "" : "black"; //#007bff

      this.setState({ navBackground: backgroundcolor });
    });
  }

  render() {
    return (
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          fixed="top"
          className="navbar-header"
          style={{ backgroundColor: "black" }}
        >
          <div className="container">
            <Navbar.Brand
              className="brandlogo"
              as={Link}
              to="/"
              id="brand-text"
              style={{ fontWeight: 700 }}
            >
              <img alt="" src={logoSvg} className="brandimg" />
              &nbsp;&nbsp;Messiah Learning Institute
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="toggler"
              style={{ backgroundColor: "grey" }}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto nav-text">
                <Nav.Link as={Link} to="/about" id="nav-text">
                  About
                </Nav.Link>
                <Nav.Link as={Link} to="/service" id="nav-text">
                  Services
                </Nav.Link>
                <Nav.Link as={Link} to="/career" id="nav-text">
                  Careers
                </Nav.Link>
                <Nav.Link as={Link} to="/contact" id="nav-text">
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}

export default NavBar;

// export default function NavBar() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return (
//     <div>
//       <Navbar
//         collapseOnSelect
//         expand="lg"
//         bg="primary"
//         variant="dark"
//         fixed="top"
//       >
//         <div className="container">
//           <Navbar.Brand
//             className="brandlogo"
//             as={Link}
//             to="/"
//             style={{ fontWeight: 700 }}
//           >
//             <img alt="" src={logoSvg} width="50" height="50" className="" />
//             &nbsp;&nbsp;M.L.I
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//           <Navbar.Collapse id="responsive-navbar-nav">
//             <Nav className="ml-auto nav-text">
//               <Nav.Link as={Link} to="/about">
//                 About
//               </Nav.Link>
//               <Nav.Link as={Link} to="/">
//                 Plan
//               </Nav.Link>
//               <Nav.Link as={Link} to="/">
//                 Save
//               </Nav.Link>
//               <Nav.Link as={Link} to="/">
//                 Invest
//               </Nav.Link>
//             </Nav>
//           </Navbar.Collapse>
//         </div>
//       </Navbar>
//     </div>
//   );
// }
